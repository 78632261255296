import { useState, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFreight } from "../../../store/slices/freight/freightSlice";
import { calculateCcCharge } from "../../../store/slices/customer/customerSlice";

function OrderItemsFooter({ orderItems }) {
  const [tqSum, setTqSum] = useState(0);
  const [qrdSum, setQrdSum] = useState(0);
  const [boSum, setBoSum] = useState(0);
  const [extSum, setExtSum] = useState(0);
  const [wSurchSum, setWSurchSum] = useState(0);
  const [weightSum, setWeightSum] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const {
    subOrder: { tax, surcharge },
  } = useSelector((state) => state.subOrder);

  const {
    customerId,
    customerData: { creditcardCharge: customerCreditCharge, terms },
  } = useSelector((state) => state.customer);

  const { freightAmount, handlingCharge } = useSelector(
    (state) => state.freight,
  );

  useEffect(() => {
    dispatch(setFreight({ weight: parseFloat(weightSum) }));
  }, [weightSum]);

  useEffect(() => {
    setTqSum(
      orderItems.reduce((prevValue, item) => {
        return prevValue + (isNaN(item.tq) ? 0 : item.tq);
      }, 0),
    );

    setQrdSum(
      orderItems.reduce((prevValue, item) => {
        return prevValue + (isNaN(item.qrd) ? 0 : item.qrd);
      }, 0),
    );

    setBoSum(
      orderItems.reduce((prevValue, item) => {
        return prevValue + (isNaN(item.bo) ? 0 : item.bo);
      }, 0),
    );

    setExtSum(
      orderItems
        .reduce((prevValue, item) => prevValue + item.ext, 0)
        .toFixed(2),
    );

    setWSurchSum(
      orderItems
        .reduce((prevValue, item) => prevValue + item.wsurch, 0)
        .toFixed(2),
    );

    setWeightSum(
      orderItems
        .filter(({ itemType }) => itemType === 0)
        .reduce((prevValue, item) => {
          if (item.bo === item.qrd) {
            return prevValue;
          }
          const itemBo = isNaN(item.bo) ? 0 : item.bo;
          return (
            prevValue +
            item.unitWeight * (isNaN(item.qrd) ? 0 : item.qrd - itemBo)
          );
        }, 0)
        .toFixed(2),
    );
  }, [orderItems]);

  useEffect(() => {
    if (customerId) {
      dispatch(
        calculateCcCharge({
          customerId,
          subTotal:
            +extSum + +freightAmount + +handlingCharge + +tax + +surcharge,
          terms,
        }),
      );
    }
  }, [customerId, extSum, freightAmount, handlingCharge, tax, surcharge]);

  return (
    <>
    <table style={{width:"100%"}}>
      <thead style={{borderBottom: "dashed 1px"}}>
        <th style={{backgroundColor: "rgba(238, 237, 237, 0.699)"}}><b>Total</b></th>
        <th style={{backgroundColor: "rgb(223, 222, 222)"}}><b>Tq</b> {tqSum}</th>
        <th style={{backgroundColor: "rgba(238, 237, 237, 0.699)"}}><b>Qrd</b> {qrdSum}</th>
        <th style={{backgroundColor: "rgb(223, 222, 222)"}}><b>Bo</b> {boSum}</th>
        <th style={{backgroundColor: "rgba(238, 237, 237, 0.699)"}}><b>Ext</b> {extSum}</th>
        <th style={{backgroundColor: "rgb(223, 222, 222)"}}><b>Weight</b> {weightSum}</th>
        <th style={{backgroundColor: "rgba(238, 237, 237, 0.699)"}}><b>w/Surch</b> {wSurchSum}</th>
      </thead>
      <tbody style={{backgroundColor: "rgba(238, 237, 237, 0.699)"}}>
        <td><b>Credit Card Charges</b></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{customerCreditCharge}</td>
      </tbody>
    </table>
    </>
  );
}

export default memo(OrderItemsFooter);
